
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>全渠道SCRM管理平台</h3>
      <p>
        与传统CRM系统相比，SCRM（Social
        CRM）是以营销互动为中心的全域客户管理系统，更偏重社交化粉丝的运营和管理，尤其是对微信生态粉丝运营需求的支持。
      </p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="manage">
      <div>
        <h6>全渠道客户管理系统</h6>
        <p>
          打通客户的全渠道触点（电商、门店、官网、微信、App、小程序、线下活动、产品包装等），通过统一的身份管理汇集全渠道数据，形成多维度的用户数据画像，包含客户属性、生命周期阶段、价值评估、活跃度、标签、行为时间轴等用户全景画像。
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
      <img src="../../assets/images/jia.png" alt="">
      <div>
        <h6>社交互动统一管理</h6>
        <p>
          对客户的社交营销触点，如微信图文、微页面H5、表单、邮件模板、短信模板、App推送等，进行一站式集中管理。配备样式丰富的内容模板库，无需设计，快速实现大规模的客户触达。更支持个性化动态内容，轻松实现内容触达的千人千面需求。
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
    </div>
    <div class="operate">
      <div class="title">
        微信<br />
        SCRM运营
      </div>
      <div class="operate_c">
        <div v-for="(v, index) in operate" :key="index">
          <h6>
            <span>{{ index + 1 }}</span
            >{{ v.name }}
          </h6>
          <p>{{ v.content }}</p>
        </div>
      </div>
    </div>
    <div class="content">
      <img src="../../assets/images/SCRM01.png" />
      <div>
        <h6>微信营销自动化</h6>
        <p>
          微心动帮助用户搭建基于CDP客户数据平台+MA营销自动化的全域SCRM解决方案，可对接主流广告投放平台，进行智能化广告投放管理和效果优化。基于消费者生命周期的自动化旅程设计，实现Always-on不间断的营销运营和内容营销管理。并可对接其他第三方传统CRM系统，方案实施简便快捷。
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
    </div>
    <div class="foot">
      <h6>500＋企业的共同选择</h6>
      <p>千人千面的策略布置</p>
      <img src="../../assets/images/hzqy.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {
      operate: [
        {
          name: "一物一码",
          content:
            "帮助品牌与粉丝实现跨越线上线下的数据追溯，可批量设置二维码",
        },
        {
          name: "微信48小时",
          content: "对新粉丝48小时黄金转化周期的自动流程设置和运营",
        },
        {
          name: "个性化菜单",
          content: "可对不同类型的微信粉丝展现相应的个性化自定义微信菜单",
        },
        {
          name: "微信卡券",
          content:
            "对折扣、兑换、代金、优惠等类型的卡券进行领取、转赠、核销管理",
        },
        {
          name: "商户中心",
          content: "对社交平台体系内的交易订单、商品、微信红包等进行统一管理",
        },
        {
          name: "微信会员管理",
          content: "完善的会员营销体系，并可对接其他第三方会员管理系统",
        },
        {
          name: "服务号对话能力",
          content: "服务号对话能力(原微信导购助手)的支持，数据的对接和打通",
        },
        {
          name: "微信营销活动",
          content: "基于社交媒体的分享裂变活动和全员推广活动",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    onapply() {
      // this.childEvent(true);
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/digitization03.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    text-align: left;
    width: 7.43rem;
    margin: 0.18rem auto;
    font-size: 0.2rem;
    line-height: 0.3rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.manage {
  display: flex;
  padding: 1.36rem 2.4rem 1rem;
  justify-content: space-between;
  img{
    width: 0.56rem;
    height: 0.56rem;
    margin: auto;
  }
  div {
    width: 6.7rem;
    height: 4.24;
    box-sizing: border-box;
    padding: 0.68rem 0.6rem;
    background-color: #fffff5;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.26rem;
    }
    p {
      font-size: 0.18rem;
      color: #666;
      line-height: 0.27rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.operate {
  color: #333;
  background-color: #fffff5;
  padding: 0.68rem 2.4rem;
  display: flex;
  .title {
    width: 2.56rem;
    height: 4.81rem;
    box-sizing: border-box;
    background-color: #ffc53d;
    color: #fff;
    font-size: 0.4rem;
    text-align: center;
    padding-top: 1.52rem;
    line-height: 0.6rem;
  }
  .operate_c {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    div {
      width: 2.96rem;
      height: 2.41rem;
      box-sizing: border-box;
      padding: 0.4rem 0.34rem;
      border: 0.01rem solid #fff7e4;
      background-color: #fff;
      h6 {
        font-size: 0.24rem;
        line-height: 0.36rem;
        padding-bottom: 0.2rem;
        span {
          display: inline-block;
          width: 0.36rem;
          height: 0.36rem;
          background-color: #ffc53d;
          color: #fff;
          text-align: center;
          border-radius: 0.05rem;
          margin-right: 0.16rem;
        }
      }
      p {
        // padding: 0 0.42rem;
        font-size: 0.2rem;
        color: #666;
        line-height: 0.3rem;
      }
    }
  }
}
.content {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  img {
    width: 7.08rem;
    height: 4.38rem;
  }
  div {
    width: 7.32rem;
    padding-left: 0.88rem;
    padding-top: 0.68rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.32rem;
    }
    p {
      padding-right: 0.65rem;
      font-size: 0.18rem;
      color: #666;
      padding-bottom: 0.12rem;
      line-height: 0.27rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.content_1 {
  text-align: center;
  img {
    width: 14.4rem;
    height: 5.64rem;
  }
}
.content_2 {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  img {
    width: 7.08rem;
    height: 4.38rem;
  }
  div {
    width: 5.79rem;
    padding-left: 0.58rem;
    padding-top: 0.38rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.32rem;
    }
    p {
      font-size: 0.18rem;
      color: #666;
      padding-bottom: 0.12rem;
      line-height: 0.27rem;
      padding-right: 0.1rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.foot {
  h6 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.2rem;
  }
  p {
    font-size: 0.18rem;
    color: #666;
    text-align: center;
  }
  img {
    width: 19.2rem;
    height: 7.88rem;
  }
}
</style>
